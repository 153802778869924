// let mode = 'remoteserver'; // 'localserver' 'remoteserver' 'noserver'
// let domain = mode === 'localserver' ? 'http://localhost:1337/' : 'https://apiv2.lauk.in/';
// let domain = mode === 'localserver' ? 'http://localhost:1337/' : 'https://eduapi.html5.run/';

let appConfig = {
    // domain: 'html5.run',
    domain: (window.location.hostname === "dev.html5.run") ? "html5.run" : "lauk.in",
    // domain: 'http://localhost:1337/',

    loginUrl: 'auth/local',
    register: 'auth/local/register',
    users: 'users',
    meetings: 'meetings',
    billing: 'billings',
    billingOrder: 'billings/order',
    billingRetry: 'billings/retry',

    forgotPassword: 'email/forgotpass',
    getToken: 'email/gettoken',
    resetPassword: 'email/resetpass',

    billingProfile: 'billing-profiles',
    billingProfileNew: 'billing-profiles/new',
    plans: 'plans',

    emailOTP: 'email-otp',
    phoneOTP: 'phone-otp',

    upload: 'upload',

    apiLocal: 'http://localhost:1337/',
    apiLauk: 'https://apiv2.lauk.in/',
    // apiLauk: 'http://localhost:1337/',
    apiClassroom: 'https://apiclsrm1.lauk.in/',
};

export default appConfig;
