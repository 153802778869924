import React from 'react';
import {Redirect, Route} from 'react-router-dom';

import {getLoggedInUser, isUserAuthenticated} from '../helpers/authUtils';

// auth
// const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const SignIn = React.lazy(() => import('../pages/user/signIn'));
const SignUp = React.lazy(() => import('../pages/user/signUp'));
const Subscription = React.lazy(() => import('../pages/user/subscription'));
const Billing = React.lazy(() => import('../pages/user/billing'));
const Success = React.lazy(() => import('../pages/user/success'));
const ForgetPassword = React.lazy(() => import('../pages/user/ForgetPassword'));
const ResetPassword = React.lazy(() => import('../pages/user/ResetPassword'));

// handle auth and authorization
const PrivateRoute = ({component: Component, roles, ...rest}) => (
    <Route
        {...rest}
        render={props => {
            if (!isUserAuthenticated()) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{pathname: '/signup', state: {from: props.location}}}/>;
            }
            const loggedInUser = getLoggedInUser();
            // check if route is restricted by role
            if (roles && roles.indexOf(loggedInUser.user.type) === -1) {
                // role not authorised so redirect to home page
                return <Redirect to={{pathname: '/'}}/>;
            }
            // authorised so return component
            return <Component {...props} />;
        }}
    />
);

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/signup"/>,
    route: PrivateRoute,
};

// const appRoutes = [calendarAppRoutes, emailAppRoutes, projectAppRoutes, taskAppRoutes];
const appRoutes = [];

// auth
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/forget-password',
            name: 'Forget Password',
            component: ForgetPassword,
            route: Route,
        },
        {
            path: '/signin/:plan/:id',
            name: 'Sign In',
            component: SignIn,
            route: Route,
        },
        {
            path: '/signin',
            name: 'Sign In',
            component: SignIn,
            route: Route,
        },
        {
            path: '/signup/:plan/:id',
            name: 'Sign Up',
            component: SignUp,
            route: Route,
        },
        {
            path: '/forget-password',
            name: 'ForgetPassword',
            component: ForgetPassword,
            route: Route,
        },
        {
            path: '/signup',
            name: 'Sign Up',
            component: SignUp,
            route: Route,
        },
        {
            path: '/offer/signup/:offer',
            name: 'Sign Up',
            component: SignUp,
            route: Route,
        },
        {
            path: '/subscription',
            name: 'Subscription',
            component: Subscription,
            route: Route,
        },
        {
            path: '/billing/:plan/:id',
            name: 'Billinh',
            component: Billing,
            route: Route,
        },
        {
            path: '/resetpassword/:token',
            name: 'Reset Password',
            component: ResetPassword,
            route: Route,
        },
        {
            path: '/resetpassword',
            name: 'Reset Password',
            component: ForgetPassword,
            route: Route,
        },
        {
            path: '/success',
            name: 'Success',
            component: Success,
            route: Route,
        },
    ],
};

// https://dev.lauk.in:3000/signup/1HBqV1LGOiSyQ3RzyepyaHlc/5f174f827949443ce04a41ab

// https://user.lauk.in/signin/1HBqV1LGOiSyQ3RzyepyaHlc/5f174f827949443ce04a41ab

// flatten the list of all nested routes
const flattenRoutes = routes => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach(item => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const allRoutes = [
    rootRoute,
    ...appRoutes,
    authRoutes,
];

const authProtectedRoutes = [...appRoutes];
// const authProtectedRoutes = [dashboardRoutes, ...appRoutes, pagesRoutes, componentsRoutes, chartRoutes, formsRoutes, tableRoutes];
const allFlattenRoutes = flattenRoutes(allRoutes);
export {allRoutes, authProtectedRoutes, allFlattenRoutes};
